import { addToast } from "actions/ui";
import Button from "components/Button";
import consts from "consts/consts";
import { getFromLocal } from "helpers/localStorage";
import React, { Component } from "react";
import { Trans } from "react-i18next";

export default class MyAccountButton extends Component {
  constructor(props) {
    super(props);
    this.state = {
      url: "",
    };
  }

  componentDidMount() {
    const isSafari = getFromLocal("isSafari");
    const { url, customerAuthToken, dispatch, t } = this.props;
    fetch(url, {
      headers: {
        Accept: "application/json",
        "Content-type": "application/x-www-form-urlencoded;charset=utf-8",
        "X-AN-WebService-Version": 2,
        "X-AN-WebService-IdentityKey": isSafari
          ? consts.safariApiKey
          : consts.apiKey,
        "X-AN-WebService-CustomerAuthToken": customerAuthToken,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.location) {
          this.setState({
            url: data.location,
          });
        } else {
          dispatch(
            addToast({
              text: t("Oops, something went wrong"),
              className: "error",
              duration: 4000,
            })
          );
        }
      })
      .catch((_error) => {});
  }

  render() {
    const { title, rootClassName, type } = this.props;
    const { url } = this.state;
    return (
      <Button
        rootClassName={rootClassName}
        type={type}
        href={url}
        target="_blank"
      >
        <Trans>{title}</Trans>
      </Button>
    );
  }
}
